header {
  .header-nav__round-icon {
    @include media-breakpoint-down(lg) {
      font-size: 0;
    }
  }  


  /* Blockcart */

  .blockcart__count {
    @include transition(0.15s ease);

    &:empty {
      display: none;
    }
  }

  .inactive .blockcart__count {
    display: none;
  }

  .shopping-cart > .blockcart__count {
    display: none;
}

  .inactive .shopping-cart a {
    pointer-events: none;
  }


}

