h2.subcategory-name {
  font-size: $font-size-base;
  font-weight: $weight-bold;
  text-align: center;
}

.subcategory-image img {
  max-height: 7rem;
  width: auto;
}

#subcategories ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  grid-auto-rows: 1fr;
}

//block categories
.block-categories {
  box-shadow: $card-box-shadow;
  a {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }

  li.chosen {
    > div > a {
      font-weight: $weight-bold;
    }
  }

  .first-line.bg-primary > a {
    color: $white;
  }

  .first-line.bg-primary > i {
    display: none;
  }
  .first-line >a {
    text-transform: uppercase;
  }

  ul.category-sub-menu .collapse.show {
    margin-bottom: $spacer
  }

  a.category-sub-link {
    position: relative;
    left: 22px;
    &::before {
      content: " ";
      width: 12px;
      height: 1px;
      background: #4d4d4d;
      position: relative;
      left: -20px;
      top: 13px;
      z-index: 9999;
      display: block;
  }
  }

}

.first-line.bg-primary > div > .close {
  display: none;
}

.first-line:not(.bg-primary) > div > .open {
  display: none;
}
.category-sub__item {
  display: block;
  width: 100%;
}
.category-sub__item--0 {
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded="true"] {
  &.remove {
    display: block;
  }
  &.add {
    display: none;
  }
}

.collapse-icons {
  width: $spacer;
  height: $spacer;
  img {
    right: 0;
    top: $spacer/2;
  }
}

.featured-categories {
  margin-bottom: $spacer * 4;
  .row {
    row-gap: $spacer * 2.5;
  }

  .category-thumbnail {
    margin-right: $spacer * 1.25;
    img {
      width: 100%;
      height: 100%;
      max-height: $spacer * 12;
      max-width: $spacer * 10;
      object-fit: scale-down;
    }
  }
  .category-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $font-size-base;
    }
  }
}

#category-description-full {
  display: none;
}

.category_description_action {
  color: $primary;
  margin-bottom: $spacer * 1.25;
  display: block;
  a {
    text-decoration: underline;
  }
}
