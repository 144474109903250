.block_newsletter {
    .newsletter-icon {
        width: $spacer*3;
        height: $spacer*3;
    }
    @include media-breakpoint-desktop {
        input[type=email] {
            min-width: 32rem;
        }
    }
    h2 {
        font-size: clamp($spacer*1.75, 3vW, #{$display3-size});
        font-weight: 700;
    }


}