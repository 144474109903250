.product-line-actions{
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}
.product-line-grid{
  .product-line__title{
    margin-bottom: $spacer*.75;
    display: inline-block;
    color: $body-color;
  }
  .product-price{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .discount{
      font-size: $font-size-sm;
      line-height: 1;
    }
  }
  .product-discount{
    margin-right: $spacer/2;
  }

}
.product-line__img{
  margin-right: $spacer;
}
button.btn.btn-touchspin {
  padding: 0;
}
.bootstrap-touchspin {
  position:relative
}
.input-group-btn-vertical {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    width: 4rem;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.bootstrap-touchspin-down::before, .bootstrap-touchspin-up::before {
  content: "-";
  position: absolute;
  font-family: 'Inter', 'Arial Narrow Bold', sans-serif;}

.bootstrap-touchspin-up::before {
    content: "+";}

.input-group>.form-control.cart-line-product-quantity {
  width: $spacer*3;
}
@include media-breakpoint-mobile {
  .product-line__img{
    width: 100px;
    height: auto;
  }
  .product-line-actions {
    margin-top: $spacer;
    .product-price{
      margin:0;
    }
  }
}

.product-customization-line {
  text-wrap:nowrap
}
@include media-breakpoint-mobile() {
  .product-line__img{
    width: $size-base;
  }
  .product-line-actions {
    .product-price{
      margin:0 
    }
  }

}

.card-header, .page-header, .card-footer, .page-footer {
  border:none
}

.cart__card-body{
  position: relative;
 .cart__card-loader{
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   align-items: center;
   justify-content: center;
   display: none;
   background-color: rgba($white, .85);
   opacity: 0;
   @include transition($transition-fade);
   z-index: 3;
 }
  &.is--loading{
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }

  }
}

/** Cart laoder */

.loading {
  filter: blur(8px);
}

.cart-loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999999;
}

.cart-loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #424242;
  background: -moz-linear-gradient(left, #424242 10%, rgba(66,66,66, 0) 42%);
  background: -webkit-linear-gradient(left, #424242 10%, rgba(66,66,66, 0) 42%);
  background: -o-linear-gradient(left, #424242 10%, rgba(66,66,66, 0) 42%);
  background: -ms-linear-gradient(left, #424242 10%, rgba(66,66,66, 0) 42%);
  background: linear-gradient(to right, #424242 10%, rgba(66,66,66, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.cart-loader:before {
  width: 50%;
  height: 50%;
  background: #424242;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.cart-loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@include media-breakpoint-up(md) {
  .nav-tabs--center{
    justify-content: center;
  }
}


.checkout-process {
  .card-header {
    padding: $spacer * 1.25;
  }
}

#content-hook_payment_return * {
  border: none!important;
}

#spstepcheckout #spsco_one .form-group.hidden {
display: none;
}


.product-line__body{
  padding-right: $spacer/4;
}

//order confirmation table
.total-value{
  font-weight: 700;
}
.table__title-head{
  margin-bottom: 0;
  font-size: $font-size-base;

}
/* CART */

.promo-code-alert {
  display: none;
}
.cancel-promo{
  text-align: center;
}
.card-body .separator{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

.cart-grid-right .cart-summary {
  position: sticky;
  top: $spacer * 10;
}

.cart-summary-line{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.cart-total{
font-weight: bold;
}
.card-body--summary{
.cart-total{
  @extend .total-value;
  .label{
    margin-bottom: 0;
  }
}
  &.card-body{
    padding-bottom: 0;
  }
}
.media-list__item:not(:last-child){
  margin-bottom: $spacer/2;

}

//cart summary
.link__showsummary{
  .material-icons{
    @extend .icon-collapse;
  }
  &[aria-expanded=true] .material-icons{
    transform: rotateZ(180deg);

  }
}
.cart-item:not(:last-child){
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}

.cart_total_price .alert-info {
  max-width: $spacer*25;
}

.promo-code-button{
  text-align: center;
  .collapse-button[aria-expanded=true] {
    display: none;
  }
}
.promo-highlighted{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts{
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-600;
  .code{
    color: $tertiary;
  }
}
.form__add-voucher{
  margin-bottom: $spacer;
}
.promo-code{
  &__content{

    padding: $card-spacer-x;
    background-color: $promo-code-bg;
  }
 .collapse-button{
   text-align: center;
   display: block;
   color: $primary;
 }
 #discount_name {
  width: 100%;
 }
}
