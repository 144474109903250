button.slick-arrow {
  width: 25px;
  height: 25px;
  border: none;
  font-size: 0;
  margin: 0 $spacer/2;
  background: url("/themes/4works-extra-theme/assets/img/arrow-right.svg")
    center center no-repeat;
  background-size: contain;
  &.slick-prev {
    background: url("/themes/4works-extra-theme/assets/img/arrow-left.svg")
      center center no-repeat;
  }
}

#homeslider {
  button.slick-arrow {
    position: relative;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }


  p {
    font-size: $font-size-base * 1.75;
    font-weight: 200;
    @include media-breakpoint-down(md) {
      font-size: $font-size-base;
    }
  }
  .slider-content .btn {
    padding: $spacer * 1.25;
    font-size: $font-size-base * 1.125;
  }
}

.carousel-arrow-navigation {
  right: $spacer * 5;
  bottom: $spacer * 5;
  @include media-breakpoint-down(sm) {
    right: $spacer * 4;
    bottom: $spacer * 6;
  }

  @include media-breakpoint-down(xs) {
    right: $spacer * 1;
    bottom: $spacer * 4;
  }
}

.carousel-image {
  border-radius: $btn-radius;
}

.homepageslider-container {
  .slider-content {
    position: absolute;
  }

  @include media-breakpoint-down(lg) {
    .slider-content,
    .slider-content.right {
      left: 0;
      width: 100%;

      &::before {
        content: "";
        background: linear-gradient(
          125deg,
          $primary,
          transparentize($primary, 0.5)
        );
        opacity: 0.8;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    img {
      position: absolute;
    }
  }
}

#homeslider,
#discount-slider,
.product-miniature {
  .styled {
    background: $secondary;
    border-radius: $border-radius-lg;
    padding: $spacer;
    font-size: $small-font-size;
    max-width: max-content;
  }

  ul.slick-dots {
    display: flex !important;
  }
}

.slider-content {
  top: 0;
  min-height: 100%;
  width: 50%;
  @include media-breakpoint-down(md) {
    width: 100%;
    color:white;

  &::before {
    content: "";
    background: linear-gradient(
      125deg,
      darken($primary, 10%),
      transparentize(darken($primary, 5%), 0.6)
    );
    opacity: 0.8;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    
  }

  }
  &.right {
    left: 50%;
    @include media-breakpoint-down(md) {
      left: 0;
    }
  }

  * {
    z-index: 99999;
  }
  h1,h2 {
    font-size: clamp($spacer*1.75, 5vW, #{$display1-size});
  }
}



